<template>
  <div class="refraction">
    <h3 style="margin-top: 5vh;">散瞳验光</h3>
    <div class="ygfs">
      <p>验光方式</p>
      <el-radio-group v-model="postData.S_OP_Json.S_OP_R_CheckType" class="sgfs-xz">
        <el-radio label="0">小瞳验光</el-radio>
        <el-radio label="1">散瞳</el-radio>
        <el-radio label="2">左瞳</el-radio>
        <el-radio label="3">右瞳</el-radio>
      </el-radio-group>
      <div class="styy" v-if="postData.S_OP_Json.S_OP_R_CheckType != 0">
        <p>散瞳用药</p>
        <el-select
            class="styy-list"
            v-model="postData.S_OP_Json.S_OP_R_santong"
            allow-create
            collapse-tags
            default-first-option
            placeholder="请选择或输入">
          <el-option
              v-for="item in styyList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <p v-if="qx.uf_r_oc" class="title">电脑验光</p>
    <div v-if="qx.uf_r_oc" class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>瞳距</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OC_DSOD, -30, 20)?'cw':'', srval==='S_OP_R_OC_DSOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OC_DSOD, 'S_OP_R_OC_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_R_OC_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OC_DCOD, -10, 9)?'cw':'', srval==='S_OP_R_OC_DCOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OC_DCOD, 'S_OP_R_OC_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_R_OC_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OC_XOD, 0, 180)?'cw':'', srval==='S_OP_R_OC_XOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OC_XOD, 'S_OP_R_OC_XOD')" v-model="postData.S_OP_Json.S_OP_R_OC_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OC_PupilDistance_OD, 20, 100)?'cw':'', srval==='S_OP_R_OC_PupilDistance_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OC_PupilDistance_OD, 'S_OP_R_OC_PupilDistance_OD')" v-model="postData.S_OP_Json.S_OP_R_OC_PupilDistance_OD" placeholder="请输入内容"></el-input>mm</li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OC_DSOS, -30, 20)?'cw':'', srval==='S_OP_R_OC_DSOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OC_DSOS, 'S_OP_R_OC_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_R_OC_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OC_DCOS, -10, 9)?'cw':'', srval==='S_OP_R_OC_DCOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OC_DCOS, 'S_OP_R_OC_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_R_OC_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OC_XOS, 0, 180)?'cw':'', srval==='S_OP_R_OC_XOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OC_XOS, 'S_OP_R_OC_XOS')" v-model="postData.S_OP_Json.S_OP_R_OC_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OC_PupilDistance_OS, 20, 100)?'cw':'', srval==='S_OP_R_OC_PupilDistance_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OC_PupilDistance_OS, 'S_OP_R_OC_PupilDistance_OS')" v-model="postData.S_OP_Json.S_OP_R_OC_PupilDistance_OS" placeholder="请输入内容"></el-input>mm</li>
        </ul>
      </div>
      <el-button class="last" type="primary" @click="showdrsb=true">导入设备数据</el-button>
    </div>


    <div style="text-align: left;padding-top: 20px;">
      <el-select class="xzlex"  v-model="valTypeidx" placeholder="请选择类型">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="dqwj" style="margin-left: 30px;" type="info">读取文件</el-button>
    </div>
    <UPfile :type="'RO'" style="margin-top: 20px" :imglists="upimglist"></UPfile>

    <p  class="title mar-20" v-if="qx.uf_r_or || type" >检影验光</p>
    <div class="srA" v-if="qx.uf_r_or || type">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>视力</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OR_DSOD, -30, 20)?'cw':'', srval==='S_OP_R_OR_DSOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OR_DSOD, 'S_OP_R_OR_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_R_OR_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OR_DCOD, -10, 9)?'cw':'', srval==='S_OP_R_OR_DCOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OR_DCOD, 'S_OP_R_OR_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_R_OR_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OR_XOD, 0, 180)?'cw':'', srval==='S_OP_R_OR_XOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OR_XOD, 'S_OP_R_OR_XOD')" v-model="postData.S_OP_Json.S_OP_R_OR_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OR_VAOD, 0.01, 2)?'cw':'', srval==='S_OP_R_OR_VAOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OR_VAOD, 'S_OP_R_OR_VAOD')" v-model="postData.S_OP_Json.S_OP_R_OR_VAOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OR_DSOS, -30, 20)?'cw':'', srval==='S_OP_R_OR_DSOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OR_DSOS, 'S_OP_R_OR_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_R_OR_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OR_DCOS, -10, 9)?'cw':'', srval==='S_OP_R_OR_DCOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OR_DCOS, 'S_OP_R_OR_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_R_OR_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OR_XOS, 0, 180)?'cw':'', srval==='S_OP_R_OR_XOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OR_XOS, 'S_OP_R_OR_XOS')" v-model="postData.S_OP_Json.S_OP_R_OR_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OR_VAOS, 0.01, 2)?'cw':'', srval==='S_OP_R_OR_VAOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OR_VAOS, 'S_OP_R_OR_VAOS')" v-model="postData.S_OP_Json.S_OP_R_OR_VAOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
      <el-button @click="tb(0)" class="last" icon="el-icon-refresh" type="primary">同步数据</el-button>
    </div>

    <p class="title mar-20" v-if="qx.uf_r_ol || type">综合验光</p>
    <div class="srA" v-if="qx.uf_r_ol || type">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>视力</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
<!--          <li><el-input :class="Number(postData.S_OP_Json.S_OP_R_OL_DSOD) > 20|| Number(postData.S_OP_Json.S_OP_R_OL_DSOD) < -30?'cw':''" @change="zh('S_OP_R_OL_DSOD',1)" v-model="postData.S_OP_Json.S_OP_R_OL_DSOD" placeholder="请输入内容"></el-input></li>-->
<!--          <li><el-input :class="Number(postData.S_OP_Json.S_OP_R_OL_DCOD) > 9|| Number(postData.S_OP_Json.S_OP_R_OL_DCOD) < -10?'cw':''" @change="zh('S_OP_R_OL_DCOD',1)" v-model="postData.S_OP_Json.S_OP_R_OL_DCOD" placeholder="请输入内容"></el-input></li>-->
<!--          <li><el-input :class="Number(postData.S_OP_Json.S_OP_R_OL_XOD) > 180|| Number(postData.S_OP_Json.S_OP_R_OL_XOD) < 0?'cw':''" @change="zh('S_OP_R_OL_XOD')" v-model="postData.S_OP_Json.S_OP_R_OL_XOD" placeholder="请输入内容"></el-input></li>-->
<!--          <li><el-input :class="postData.S_OP_Json.S_OP_R_OL_VAOD !== ''&&(Number(postData.S_OP_Json.S_OP_R_OL_VAOD) > 1.5|| Number(postData.S_OP_Json.S_OP_R_OL_VAOD) < 0.01)?'cw':''" @change="zh('S_OP_R_OL_VAOD')" v-model="postData.S_OP_Json.S_OP_R_OL_VAOD" placeholder="请输入内容"></el-input></li>-->
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OL_DSOD, -30, 20)?'cw':'', srval==='S_OP_R_OL_DSOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OL_DSOD, 'S_OP_R_OL_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_R_OL_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OL_DCOD, -10, 9)?'cw':'', srval==='S_OP_R_OL_DCOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OL_DCOD, 'S_OP_R_OL_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_R_OL_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OL_XOD, 0, 180)?'cw':'', srval==='S_OP_R_OL_XOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OL_XOD, 'S_OP_R_OL_XOD')" v-model="postData.S_OP_Json.S_OP_R_OL_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OL_VAOD, 0.01, 2)?'cw':'', srval==='S_OP_R_OL_VAOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OL_VAOD, 'S_OP_R_OL_VAOD')" v-model="postData.S_OP_Json.S_OP_R_OL_VAOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OL_DSOS, -30, 20)?'cw':'', srval==='S_OP_R_OL_DSOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OL_DSOS, 'S_OP_R_OL_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_R_OL_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OL_DCOS, -10, 9)?'cw':'', srval==='S_OP_R_OL_DCOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OL_DCOS, 'S_OP_R_OL_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_R_OL_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OL_XOS, 0, 180)?'cw':'', srval==='S_OP_R_OL_XOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OL_XOS, 'S_OP_R_OL_XOS')" v-model="postData.S_OP_Json.S_OP_R_OL_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_OL_VAOS, 0.01, 2)?'cw':'', srval==='S_OP_R_OL_VAOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_OL_VAOS, 'S_OP_R_OL_VAOS')" v-model="postData.S_OP_Json.S_OP_R_OL_VAOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
      <el-button @click="tb(1)" class="last" icon="el-icon-refresh" type="primary">同步数据</el-button>
    </div>

    <div class="bottom" v-if="type">
      <p>备注</p>
      <el-input
          class="bzs"
          type="textarea"
          placeholder="请输入内容"
          v-model="postData.S_OP_Json.S_OP_R_O_Remark"
          :autosize="{ minRows: 1, maxRows: 1 }"
          resize="none"
          show-word-limit
      >
      </el-input>
    </div>


    <el-dialog
        title=""
        :visible.sync="showdrsb"
        width="70%"
    >
      <ImportEquipment @getdata="drdata" :types="'oo'"></ImportEquipment>
      <span slot="footer" class="dialog-footer">
         <el-button @click="showdrsb = false">取 消</el-button>
       </span>
    </el-dialog>

    <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "refraction",
  data() {
    return {
      isdrc: true,
      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      iszf: 0,
      upimglist: [],
      valTypeidx: 1,
      options: [],
      showdrsb: false,
      bz: '',
      input: '',
      ids: 4,
      chooseid: '',
      qx: {},
      postData: {
        S_OP_ChooseId: "",
        S_OP_CustomerId: "",
        S_OP_ExpertId: "",
        S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json: {
          S_OP_R_santong: '',
          S_OP_R_CheckType: '',
          S_OP_R_SC_DSOD: '',
          S_OP_R_ChooseId: '',
          S_OP_R_SC_PupilDistance_OD: '',
          S_OP_R_SC_PupilDistance_OS: '',
          S_OP_R_part1_isDone: '',
          S_OP_R_SC_DCOS: '',
          S_OP_R_SC_XOD: '',
          S_OP_R_SC_DCOD: '',
          S_OP_R_SC_DSOS: '',
          S_OP_R_SC_XOS: '',
          S_OP_R_SR_DSOS: '',
          S_OP_R_OR_VAOD: '',
          S_OP_R_SS_BaseOS: '',
          S_OP_R_SS_DVAOS: '',
          S_OP_R_OC_XOD: '',
          S_OP_R_OL_VAOD: '',
          S_OP_R_SS_LeadingEye: '',
          S_OP_R_SR_VAOS: '',
          S_OP_R_OC_PupilDistance_OS: '',
          S_OP_R_SR_XOD: '',
          S_OP_R_OR_DSOS: '',
          S_OP_R_OL_XOD: '',
          S_OP_R_SS_NVAOS: '',
          S_OP_R_OL_DSOS: '',
          S_OP_R_OC_PupilDistance_OD: '',
          S_OP_R_SS_XOS: '',
          S_OP_R_OR_VAOS: '',
          S_OP_R_OC_DCOD: '',
          S_OP_R_SS_BCC: '',
          S_OP_R_OL_VAOS: '',
          S_OP_R_SS_DCOD: '',
          S_OP_R_SS_AddOD: '',
          S_OP_R_SS_Stereopsis4Far: '',
          S_OP_R_SS_Stereopsis4Near: '',
          S_OP_R_SS_EyeBalanceTool: '',
          S_OP_R_SS_Worth4Near: '',
          S_OP_R_SS_Remark: '',
          S_OP_R_SS_Age: '',
          S_OP_R_SS_SVOD: '',
          S_OP_R_OC_santong: '',
          S_OP_R_SS_BaseOD: '',
          S_OP_R_SS_EyeBalance: '',
          S_OP_R_OC_DCOS: '',
          S_OP_R_SR_DCOD: '',
          S_OP_R_OC_DSOD: '',
          S_OP_R_SS_DCOS: '',
          S_OP_R_SS_XOD: '',
          S_OP_R_OR_XOS: '',
          S_OP_R_SS_PDOD: '',
          S_OP_R_SS_DSOD: '',
          S_OP_R_SS_NRA: '',
          S_OP_R_SS_SVOS: '',
          S_OP_R_OR_DCOD: '',
          S_OP_R_SS_AddOS: '',
          S_OP_R_OL_DCOD: '',
          S_OP_R_SS_DVAOD: '',
          S_OP_R_SS_PrismOD: '',
          S_OP_R_OC_XOS: '',
          S_OP_R_SR_DCOS: '',
          S_OP_R_part2_isDone: '',
          S_OP_R_OC_DSOS: '',
          S_OP_R_SR_DSOD: '',
          S_OP_R_SS_PrismOS: '',
          S_OP_R_SR_XOS: '',
          S_OP_R_SS_PRA: '',
          S_OP_R_SS_PDOS: '',
          S_OP_R_SS_NVAOD: '',
          S_OP_R_SS_DSOS: '',
          S_OP_R_OL_XOS: '',
          S_OP_R_SR_VAOD: '',
          S_OP_R_OR_DCOS: '',
          S_OP_R_OR_XOD: '',
          S_OP_R_OR_DSOD: '',
          S_OP_R_OL_DCOS: '',
          S_OP_R_O_Remark: '',
          S_OP_R_OL_DSOD: '',
          S_OP_R_SS_Worth4Far: ''
        },
        S_OP_Model: "S_OP_Refractive180726",
        S_OP_Time: "",
        S_OP_Type: "Refractive",
        S_OP_XML: ""
      },
      ygfsList: '',
      styyList: [
        {
          value: '0.5%复方托吡卡胺(美多丽)',
          label: '0.5%复方托吡卡胺(美多丽)'
        },
        {
          value: '1%环戊通',
          label: '1%环戊通'
        },
        {
          value: '1%阿托品膏',
          label: '1%阿托品膏'
        },
        {
          value: '阿托品已散瞳',
          label: '阿托品已散瞳'
        },
        {
          value: ' ',
          label: ' '
        }
      ]
    }
  },
  props: {
    idx: {
      default: -1
    },
    type: {
      default: 0
    }
  },
  watch: {
    idx: function (n, o) {

    },
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upRefractive', n)
      },
      deep: true
    },
    ygfsList: {
      handler(n, o) {
        let arr = [0,0,0,0]
        arr[Number(n)] = 1
        this.postData.S_OP_Json.S_OP_R_CheckType = arr.join(',')
      },
      deep: true
    }
  },
  created() {
    document.addEventListener('click', this.getXY, false)
    this.qx = this.$store.state.users.jurisdiction
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
    this._api.eyeTest.getRefractive()
        .then(res => {
          if (res.GetListResult && res.GetListResult.length > 0) {
            res.GetListResult[0].S_OP_Json = this._clJson(res.GetListResult[0].S_OP_Json)
            this.postData = res.GetListResult[0]
          }else {
            this.postData.S_OP_ChooseId = this.$store.state.physicianVisits.xzConsulting
            this.postData.S_OP_CustomerId = this.$store.state.users.CsUser.CustomerId
            this.postData.S_OP_Json.CL_RE_IP_ChooseId = this.$store.state.physicianVisits.xzConsulting
            this.postData.S_OP_Json.S_OP_R_ChooseId = this.$store.state.physicianVisits.xzConsulting
          }
        })

    this._api.publicApi.getOCRType('电脑验光')
        .then(res => {
          let arr = []
          this.baiduTo = res.BaiduToken
          if (res.GetListResult && res.GetListResult.length > 0) {
            this.oparr = JSON.parse(JSON.stringify(res.GetListResult))
            for (let i = 0; i < res.GetListResult.length; i++) {
              arr.push({
                value: i + 1,
                label: res.GetListResult[i].BDOCR_TempName
              })
            }
          }
          this.options = arr
        })

  },
  methods: {
    tb(idx) {
      switch (idx) {
        case 0:
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OR_DSOD', this.postData.S_OP_Json.S_OP_R_OC_DSOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OR_DCOD', this.postData.S_OP_Json.S_OP_R_OC_DCOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OR_XOD', this.postData.S_OP_Json.S_OP_R_OC_XOD)

          this.$set(this.postData.S_OP_Json, 'S_OP_R_OR_DSOS', this.postData.S_OP_Json.S_OP_R_OC_DSOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OR_DCOS', this.postData.S_OP_Json.S_OP_R_OC_DCOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OR_XOS', this.postData.S_OP_Json.S_OP_R_OC_XOS)
          break
        case 1:
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OL_DSOD', this.postData.S_OP_Json.S_OP_R_OR_DSOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OL_DCOD', this.postData.S_OP_Json.S_OP_R_OR_DCOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OL_XOD', this.postData.S_OP_Json.S_OP_R_OR_XOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OL_VAOD', this.postData.S_OP_Json.S_OP_R_OR_VAOD)

          this.$set(this.postData.S_OP_Json, 'S_OP_R_OL_DSOS', this.postData.S_OP_Json.S_OP_R_OR_DSOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OL_DCOS', this.postData.S_OP_Json.S_OP_R_OR_DCOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OL_XOS', this.postData.S_OP_Json.S_OP_R_OR_XOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_OL_VAOS', this.postData.S_OP_Json.S_OP_R_OR_VAOS)
          break
      }
    },

    drdata(data) {
      if (data) {
        this.postData.S_OP_Json.S_OP_R_OC_DSOD = data.RightSPH?(data.RightSPH).toString():''
        this.postData.S_OP_Json.S_OP_R_OC_DCOD = data.RightCYL?(data.RightCYL).toString():''
        this.postData.S_OP_Json.S_OP_R_OC_XOD = data.RightAxis?(data.RightAxis).toString():''
        this.postData.S_OP_Json.S_OP_R_OC_PupilDistance_OD = (data.PD/2)?(data.PD/2).toString():''

        this.postData.S_OP_Json.S_OP_R_OC_DSOS = data.LeftSPH?(data.LeftSPH).toString():''
        this.postData.S_OP_Json.S_OP_R_OC_DCOS = data.LeftCYL?(data.LeftCYL).toString():''
        this.postData.S_OP_Json.S_OP_R_OC_XOS = data.LeftAxis?(data.LeftAxis).toString():''
        this.postData.S_OP_Json.S_OP_R_OC_PupilDistance_OS = data.PD/2?(data.PD/2).toString():''

        this.showdrsb = false
      }
    },

    zh(key, type=0) {
      if (type) {
        if (Number(this.postData.S_OP_Json[key]) > 0 && this.postData.S_OP_Json[key].toString().indexOf('+')==-1) {
          this.postData.S_OP_Json[key] = "+" + this.postData.S_OP_Json[key]
        }
      }
      this.postData.S_OP_Json[key] = this.postData.S_OP_Json[key] !== ''?this.postData.S_OP_Json[key].toString():''
    },


    keyd(e) {
      if (pf.isMobile()) {
        e.returnValue= false
      }
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, iszf) {
      // console.log(e)
      if (pf.isMobile()) {
        document.activeElement.blur();
      }
      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData.S_OP_Json, this.srval, e)
      if (this.postData.S_OP_Json[this.srval] && !isNaN(Number(this.postData.S_OP_Json[this.srval])) && this.iszf) {
        if (this.postData.S_OP_Json[this.srval] && Number(this.postData.S_OP_Json[this.srval]) > 0 && this.postData.S_OP_Json[this.srval].indexOf('+') < 0  && this.postData.S_OP_Json[this.srval].indexOf('-') < 0) {
          this.$set(this.postData.S_OP_Json, this.srval, '+'+e)
        }
      }
    },


    dqwj() {
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登录请登录！', '提示')
            .then(res => {this.$router.push({path: '/employeesLanding'})})
      }
      if (!this.valTypeidx) {
        return this.$alert('请选择类型！', '提示')
      }

      if (!this.isdrc) return

      this.isdrc = false

      this.wjsc()

      let str = this.$store.state.physicianVisits.xzConsulting.slice(0,8).toUpperCase()
      let name = this.$store.state.users.CsUser.CustomerName,
          ys = this.$store.state.users.employees.ExpertName,
          temid = this.oparr[this.valTypeidx-1].BDOCR_TempId
      let obj = {
        ChooseNo: str,
        Expert: ys,
        Customer: name,
        CheckType: "电脑验光",
        TempId: temid,
        BDToken: this.baiduTo,
        PageSite: this.oparr[this.valTypeidx-1].BDOCR_PageSite,
        ImageSite: this.oparr[this.valTypeidx-1].BDOCR_ImageSite,
        "OcrId": this.oparr[this.valTypeidx-1].BDOCR_Id,
        "TempName": this.oparr[this.valTypeidx-1].BDOCR_BrandName,
        "CompanyId": this.$store.state.users.user.Company.CompanyId,
        "ShopId": this.$store.state.bases.shop.ShopId,
        "ChooseId": this.$store.state.physicianVisits.xzConsulting,
        "HealthCheckId":'',
        "CustomerId": this.$store.state.users.CsUser.CustomerId,
        "ExpertId":  this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000"
      }

      window.bound.selReport(JSON.stringify(obj));

    },

    wjsc() {
      let _this = this
      window.getReportResult = function (res) {
        _this.isdrc = true
        if (res.BaiduResult) {
          let datas = JSON.parse(res.BaiduResult).data
          if (datas.ret) {
            let item = {},obj = JSON.parse(JSON.stringify(_this.postData))
            for (let i = 0; i < datas.ret.length; i++) {
              let str = datas.ret[i].word?datas.ret[i].word.toString():''
              switch (datas.ret[i].word_name) {
                case 'S_OP_R_OC_DSOD':
                  obj.S_OP_R_OC_DSOD = str
                  break
                case 'S_OP_R_OC_DSOS':
                  obj.S_OP_R_OC_DSOS = str
                  break
                case 'S_OP_R_OC_DCOD':
                  obj.S_OP_R_OC_DCOD = str
                  break
                case 'S_OP_R_OC_DCOS':
                  obj.S_OP_R_OC_DCOS = str
                  break
                case 'S_OP_R_OC_XOD':
                  obj.S_OP_R_OC_XOD = str
                  break
                case 'S_OP_R_OC_XOS':
                  obj.S_OP_R_OC_XOS = str
                  break
                case 'S_OP_R_OC_PupilDistance_OD':
                  obj.S_OP_R_OC_PupilDistance_OD = str
                  break
                case 'S_OP_R_OC_PupilDistance_OS':
                  obj.S_OP_R_OC_PupilDistance_OS = str
                  break
              }
            }
            _this.postData.S_OP_Json = obj
          }
        }
        if (res.ReportImages && res.ReportImages.length) {
          for (let i = 0; i < res.ReportImages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportPages[i].ImageBase64, 'RO')
                .then(res => {
                  _this.upimglist.push(res)
                })
          }
        }
        if (res.ReportPages && res.ReportPages.length) {
          for (let i = 0; i < res.ReportPages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportPages[i].PageBase64, 'RO')
                .then(res => {
                  _this.upimglist.push(res)
                })
          }
        }
      }
    },
  },
  components: {
    ImportEquipment: () => import('@/components/specialSurveys/refractive/importEquipment'),
    KEYS: () => import('@/components/keyboard/keyHome'),
    UPfile: () => import('@/components/CommonComponents/upFile')
  },
  beforeDestroy() {
    document.removeEventListener('click', this.getXY)
  },

}
</script>

<style scoped lang="scss">
  .refraction {
    width: 90%;
    margin: 0 auto;
    overflow-y: auto;
  }
  .mar-20 {margin-top: 30px}
  ::v-deep .refraction .el-collapse-item__header {font-size: 18px}
  .title {
    font-size: 18px;
    text-align: left;
  }
  .padding-20{padding-top: 15px;}
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 3vw;
        text-align: left;
        margin-right: 5px;
      }
    }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-left: 50px;
      margin-top: 32px;
    }
  }
  ::v-deep .srA .content-sr input:hover {background: #e8e6e6;}
  .bottom {
    margin-top: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
    .bzs {
      width: 70%;
      margin-left: 35px;
    }
  }
  .cw {background: #ef4674;}
  ::v-deep .cw .el-input__inner{background: #ef4674 !important;}
  .ygfs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 0;
    margin-top: 20px;
    .sgfs-xz {
      margin-left: 20px;
      margin-right: 40px;
    }
    .styy {
      display: flex;
      align-items: center;
      p {
        padding-right: 10px;
      }
      .styy-list {
        width: 12vw;
      }
    }
  }
  ::v-deep .xz input{background: #e8e6e6}
</style>
